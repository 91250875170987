import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "What Men Want: American Men Fess Up On Style",
  date: "2017-09-14",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170914-0.jpg",
  path: "2017-09-14-What-Men-Want",
  teaserText: "The modern American guy wants to improve his style, but needs a bit of help. "
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="What Men Want: American Men Fess Up On Style" description="The modern American guy wants to improve his style, but needs a bit of help. " author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Doneger Group Survey Reveals Most Men Want To Be More Stylish, But Find Process Painful`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The modern American guy wants to improve his style, but needs a bit of help. A new survey from The Doneger Group, the global leader in creative and merchandising strategies for fashion and retail, asked 350 American guys about their style and shopping preferences. The results reflect a consumer who clearly sees fashion as a way of standing out but who is somewhat intimated by it, presenting an opportunity for retailers to help modern men through guidance, looks and products.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The modern American guy wants to attain ‘‘chill guy’’ status, a relaxed way of showing his individuality to stay ahead in work, life and love. He’s more expressive than ever, and open to brands and retailers that can help him find his groove. This survey confirms that.`}</MDXTag>
      <ImageLandscape imageSrc="20170914-1.png" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Here are some of the surprising findings:`}</MDXTag></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Men are more style-curious than they’re credited for, with 48% of men seeing style as a means of self-expression in comparison to 33% of men who see style as “a pain,” and the rest who see it as a necessity.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`When asked what their best style statement is, the two top answers were the statement shirt (27.3%) and a haircut (25.9%), demonstrating their openness to try new things and be pampered.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`While the majority of our men (57%) describe their style as “basic” (jeans, T-shirt, sneakers, i.e. Dadcore), their style inspirations are anything but, with George Clooney and Ryan Gosling coming in as their top two style inspirations. Brands and retailers have a strong opportunity to help this guy go from basic to cool.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Our guy is more quality-conscious than ever, as 64% of men said they would be willing to pay more for clothes made with higher quality materials.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`He’s upbeat about spending in fashion, with 65% saying they will spend as much as last year, and another 15% saying they will spend more.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Here’s what we think:`}</MDXTag></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Men want style but struggle along the way. Give them style guides or delivery services that ease the process.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Men are looking for a means to stand out from the rest. A higher focus on grooming products and unique shirts is a winning strategy.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Men seek comfort but still have a classic look in mind. Deliver a new suited casual style that brings these aspirations together in a refreshing way.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Men, especially Millennials, still rely on stores for style inspiration and are subsequently still making purchases in-store. Deliver on in-store exclusives as well as experiences (e.g. how-to styling sessions).`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Across the board, the typical American guy presents a strong opportunity for business and creative growth, with just a bit more style attention and love that can yield big growth and profits. And that, everyone wants.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    